import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'

// Components
import DilanButton from '../../atoms/DilanButton'
import ComicCarrousel from '../../organisms/ComicCarrousel'
import SupportCarrousel from '../../organisms/SupportCarrousel'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Constants
import { home } from '../../../config/localization'

// Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  mapCta,
  mapTitle,
  mapLayout,
  mapButton,
  mapWrapper,
  mapOverlay,
  mapSubtitle,
  blueWrapper,
  pbCorrection,
  yellowWrapper,
  mapTitleContainer,
} from './styles.module.scss'
import CharacterHome from '../../molecules/CharacterHome'

const Home: React.FC = () => {
  const { language } = useContext(LocalizationContext)

  const [localization, setLocalization] = useState(home.Default)

  useEffect(() => {
    const newLocalization = home[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <>
      <Helmet>
        <title>
          Home - Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Bienvenido al mundo de Dilan. Disfruta nuestro comic y aprende más sobre el mundo, lenguaje y personajes que viven en Adina.'
              : 'Welcome to the world of Dilan. Enjoy our web comic and find out more about its world, language and characters that live in Adina.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, homepage'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, homepage'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <div className={`block-wrapper ${yellowWrapper}`}>
        <div className="container">
          <ComicCarrousel
            localization={{
              title: localization.comic,
              slide: localization.comicSlide,
              button: localization.comicButton,
              latest: localization.comicNew,
              spin: localization.comicSpin,
            }}
          />
        </div>
      </div>
      <div className={`block-wrapper ${mapWrapper}`}>
        <div className={`container ${mapLayout}`}>
          <div className={mapTitleContainer}>
            <div className={`${mapTitle} ${getFont('font-patua')}`}>
              {localization.world}
            </div>
            <div className={`${mapTitle} ${getFont('font-patua')}`}>
              {localization.world2}
            </div>
          </div>
          <div className={mapCta}>
            <div className={`${mapSubtitle} ${getFont('font-lato')}`}>
              {localization.worldSub}
            </div>
            <DilanButton
              type={'primary'}
              className={`${mapButton} ${getFont('font-lato', pbCorrection)}`}
              handleClick={() => {
                navigate('/world/map')
              }}
            >
              {localization.worldButton}
            </DilanButton>
          </div>
          <div className={mapOverlay} />
        </div>
      </div>
      <div className={`block-wrapper ${yellowWrapper}`}>
        <div className="container">
          <SupportCarrousel title={localization.patreon} />
        </div>
      </div>
      <div className={`block-wrapper ${blueWrapper}`}>
        <div className="container">
          <CharacterHome
            localization={{
              title: localization.character,
              button: localization.characterButton,
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Home
